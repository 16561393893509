<template>
  <auth-card btn-classes="full-width"
    :show-close-btn="true">
    <template #description>
      <el-button class="full-width"
        :disabled="loggingIn"
        @click="$router.push('/signup-email')">
        {{ $t('authentication.create-account-email') }}
      </el-button>

      <h5 class="or">
        <span>{{ $t('authentication.or') }}</span>
      </h5>

      <google-button v-if="allowsGoogleLogin"
        text="signup_with"
        :show-prompt="false"
        on-success-route="/signup-google" />
    </template>
  </auth-card>
</template>

<script>
import AuthCard from '@/components/ui-components/AuthCard.vue'
import GoogleButton from '@/components/authentication/GoogleButton.vue'
import { mapState } from 'vuex'

export default {
  name: 'SignupSelection',
  components: {
    'auth-card': AuthCard,
    'google-button': GoogleButton,
  },
  inject: ['disabledFeatures', 'isFeatureEnabled'],
  data() {
    return {
      loader: null,
    }
  },
  computed: {
    ...mapState('Auth', ['loggingIn']),
    allowsGoogleLogin() {
      return (process.env.VUE_APP_DISABLE_INTEGRATION_GOOGLE_LOGIN === 'false')
    },
  },
}
</script>

<style lang="scss" scoped>
// Fix full width button margins
.el-button:first-of-type {
  margin-top: 1rem;

  & + .el-button {
    margin-left: 0;
    margin-top: 0;
  }
}
</style>
